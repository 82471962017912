export const waitForElement = (
  selector: string,
  timeout: number = 5000,
  resolveOnTimeout = false,
): Promise<Element | undefined> => {
  return new Promise((resolve, reject) => {
    const existingElement = document.querySelector(selector);
    if (existingElement) {
      resolve(existingElement);
      return;
    }
    // eslint-disable-next-line
    let timeoutId: number;
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const element = document.querySelector(selector);
          if (element) {
            resolve(element);
            observer.disconnect();
            clearTimeout(timeoutId);
            return;
          }
        }
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    timeoutId = setTimeout(() => {
      observer.disconnect();
      if (resolveOnTimeout) {
        console.warn('Element was not found');
        resolve(undefined);
      } else {
        reject(new Error('Timeout waiting for element to appear'));
      }
    }, timeout) as unknown as number;
  });
};
