import { MountableMicrofrontendConfiguration } from '@fnz-dock/core';

export const DEFAULT_CONTAINER_CLASS = 'dock-mfe-application-container';
export const DEFAULT_WRAPPER_CLASS = 'dock-mfe-application-wrapper';
export const DEFAULT_TARGET_ELEMENT = '#mfe-application-slot';

const sanitizeId = (str: string) => {
  return str.replace(/[@\/.]/g, '-');
};

export const getMountElement = (
  configuration: MountableMicrofrontendConfiguration,
) => {
  const defaultTargetElementSelector = DEFAULT_TARGET_ELEMENT;
  const targetIdSelector =
    '#' + configuration.id.replace('@', '').replace('/', '-');

  const selectors = [
    configuration.targetElement,
    targetIdSelector,
    defaultTargetElementSelector,
  ];

  for (let i = 0; i < selectors.length; i++) {
    const mountElement = document.querySelector<HTMLElement>(selectors[i]);
    if (mountElement) {
      return mountElement;
    }
  }

  console.log('Can not find mounting point');
};

const getWrapper = (microfrontendId: string, mountElement: HTMLElement) => {
  const wrapper = mountElement.querySelector(
    `.${DEFAULT_WRAPPER_CLASS}[data-mfe-id="mfe-${sanitizeId(
      microfrontendId,
    )}"]`,
  );
  if (!wrapper) {
    const mfeWrapper = document.createElement('div');
    mfeWrapper.className = DEFAULT_WRAPPER_CLASS;
    mfeWrapper.setAttribute(
      'data-mfe-id',
      `mfe-${sanitizeId(microfrontendId)}`,
    );
    mountElement.appendChild(mfeWrapper);
    return mfeWrapper;
  }
  return wrapper;
};
export const getContainer = (
  configuration: MountableMicrofrontendConfiguration,
  mountElement: HTMLElement,
): HTMLElement => {
  if (!mountElement) {
    return;
  }
  const wrapper = getWrapper(configuration.id, mountElement);
  if (configuration.shadow && !wrapper.shadowRoot) {
    wrapper.attachShadow({ mode: 'open' });
  }
  const root = wrapper.shadowRoot || wrapper;
  const container = root.querySelector(
    `.${DEFAULT_CONTAINER_CLASS}`,
  ) as unknown as HTMLElement;
  if (!container) {
    const mfeContainer = document.createElement('div');
    mfeContainer.className = DEFAULT_CONTAINER_CLASS;
    root.appendChild(mfeContainer);
    return mfeContainer;
  }
  return container;
};

export const getDomElement = (
  configuration: MountableMicrofrontendConfiguration,
): HTMLElement => {
  return getContainer(configuration, getMountElement(configuration));
};

export const cleanDomElement = (
  configuration: MountableMicrofrontendConfiguration,
) => {
  const mountElement = getMountElement(configuration);
  if (mountElement) {
    const wrapper = getWrapper(configuration.id, mountElement);
    wrapper.remove();
  } else {
    console.log('Can not find mounting point to clean');
  }
};
