import { MOUNTED, SingleSpaCustomEventDetail } from 'single-spa';

import { DockConfiguration, getBreadcrumbManager } from '@fnz-dock/core';

type MicrofrontendConfiguration = DockConfiguration['microfrontends'][number];

export const updateBreadcrumbs = (
  evt: CustomEvent<SingleSpaCustomEventDetail>,
  configuration: DockConfiguration,
) => {
  const activeApps = getMountedApplications(
    evt.detail.appsByNewStatus[MOUNTED],
    configuration.microfrontends,
  );
  if (activeApps.length) {
    const breadcrumbManager = getBreadcrumbManager();
    const rootApp = activeApps[0];
    const matchedNavigation = configuration.navigatorConfiguration?.links.find(
      (link) => {
        return 'applicationId' in link && link.applicationId === rootApp.id;
      },
    );
    if (matchedNavigation) {
      breadcrumbManager.setBreadcrumbs([
        {
          id: rootApp.id,
          text: matchedNavigation.text,
          icon: matchedNavigation.cardIcon,
        },
      ]);
    }
  }
};
const getMountedApplications = (
  ids: string[],
  microfrontends: DockConfiguration['microfrontends'],
  ignoreIds: string[] = ['@fnz-dock/navigator'],
): (MicrofrontendConfiguration & { type: 'application' })[] => {
  return microfrontends
    .filter(
      (
        mfe: MicrofrontendConfiguration,
      ): mfe is MicrofrontendConfiguration & { type: 'application' } => {
        return mfe.type === 'application';
      },
    )
    .filter((mfe) => {
      return ids.includes(mfe.id) && !ignoreIds.includes(mfe.id);
    });
};
