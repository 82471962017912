import { MicrofrontendConfiguration } from '@fnz-dock/core';

export function isMountedMFEMatchedWithLocation(
  activeApps: string[],
  microfrontends: MicrofrontendConfiguration[],
) {
  const listOfApplicationIds = microfrontends
    .filter(
      (mfe) => mfe.type === 'application' && mfe.id !== '@fnz-dock/navigator',
    )
    .map((app) => app.id);

  const containsMFE = activeApps.some((element) =>
    listOfApplicationIds.includes(element),
  );
  return containsMFE;
}
