import { JSONValue } from '@fnz-dock/core';

export const addLoadingUI = (url: JSONValue) => {
  if (typeof url === 'string' && url.length > 0) {
    const wrapperDiv = document.createElement('div');
    wrapperDiv.className = 'dock-loading-wrapper';
    const loadingDiv = document.createElement('div');
    loadingDiv.className = 'dock-loading';
    wrapperDiv.appendChild(loadingDiv);
    document.body.appendChild(wrapperDiv);
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    link.id = 'dock-loading-style';
    document.head.appendChild(link);
  }
  return;
};

export const removeLoadingUI = () => {
  document.querySelector('.dock-loading-wrapper')?.remove();
  document.getElementById('dock-loading-style')?.remove();
};
